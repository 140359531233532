* {
  box-sizing: border-box;
}

.App {
  width: 90vw;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif;
}

/* sidebar */

.css-to4x1n-MuiBadge-badge {
  height: 10px !important;
  min-width: 10px !important;
}

.MuiBadge-root .css-1c32n2y-MuiBadge-root svg {
  color: white !important;
}

.sidebar {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 17%;
  background-color: #6666ff;
  position: fixed;
}

.sidebar-list {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.02em;
  padding-top: 80px;
}

.border-bottom {
  border-bottom: 1px solid #e5e5ff;
  width: 75% !important;
}

.sidebar-list:hover {
  cursor: pointer;
}

.sidebar-list .row1 {
  width: 100%;
  height: 80px;
  list-style-type: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans", sans-serif;
  color: white;
  margin-left: 25px;
  flex-wrap: nowrap;
}

.sidebar-top {
  margin: 0;
  display: flex;
  color: white;
  justify-content: space-around;
  align-items: center;
  padding: 25px 0 0 0;
  margin: 0 auto;
}

.sidebar-top h2 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.02em;
}

.sidebar-notifications {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar-language {
  display: flex;
  gap: 10px;
  margin-left: 9px;
}

.sidebar-notifications,
.sidebar-language:hover {
  cursor: pointer;
}

.row #sidebar-icon {
  flex: 20%;
  display: grid;
  place-items: center;
}

.row #sidebar-title {
  flex: 80%;
}

/* login page */

.login-page {
  font-family: "Mulish", sans-serif;
}

#login_with_dashed {
  border: 3px solid #6666ff;
  width: calc(100% + 6px);
  margin-left: -3px;
  height: calc(100% + 6px);
  margin-top: -3px;
  position: absolute;
  transition: transform 250ms;
  cursor: pointer;
  background: white;
}

#sub_container_login {
  background: white;
  border: 3px dashed #6666ff;
  width: 500px;
  height: 450px;
  position: relative;
  z-index: 2;
}

#login_with_dashed:hover {
  transform: translate(-15px, -20px);
}

.first-card-title {
  margin: 50px 0;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
}

label {
  display: block;
}

.login-page-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  max-width: 360px;
  margin: 120px auto;
}

.login-form-label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 9px;
}

.login-form-input {
  width: 350px;
  height: 25px;
  border: 1px solid #6666ff;
}

.audteye-logo {
  position: fixed;
  top: 5px;
  left: 0;
  right: 0;
  margin: auto;
}

.login-text {
  margin-right: 67%;
  font-size: 14px;
  font-weight: 500;
}

.login-signUp-link {
  color: red;
  text-decoration: none;
}

.submit-btn {
  padding: 8px 22px;
  margin-top: 30px;
  color: white !important;
  background: #6666ff;
  border: none;
  font-weight: bold;
}

.submit-btn:hover {
  cursor: pointer;
}

/* profile page */

.profile-page-logo {
  width: 150px;
  height: 100px;
  margin-left: auto;
}

.update-info {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 9px;
  margin-right: 55px;
}

.profile-page-info {
  margin-left: 150px;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border: 3px solid #6666ff;
}

.profile-title {
  margin-top: 50px;
  font-size: 18px;
  font-weight: bold;
}

.profile-text {
  margin-top: 10px;
  font-size: 14px;
}

.social-media {
  display: flex;
  gap: 9px;
  margin: 15px 0 50px 15px;
}

/* employees page */

.employee-container {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin-left: 50px;
}

@media only screen and (max-width: 991.9px) {
  .sidebar {
    width: 25%;
  }
}
@media only screen and (max-width: 768.9px) {
  .sidebar {
    width: 34%;
  }
}

.employee-div {
  width: 80%;
  height: 100%;
  background: #f9f9f9;
  margin: 0 0 10px 0px;
  padding: 75px 20px;
  box-shadow: 4px 4px 6px rgba(229, 233, 254, 0.55);
  border-radius: 14px;
}

input[type="checkbox"] {
  margin-right: 5px;
}

.fc-button-group button {
  background: white !important;
}

.fc-scrollgrid-sync-inner a {
  color: black !important;
  text-decoration: none;
}

.fc-button-group[aria-pressed="false"] {
  display: none;
}

.profile-page-wrapper {
  width: 55%;
  height: 50%;
}

/* admin panel page */

.admin-panel-accordion {
  width: 90%;
  margin: 60px auto 0;
}

.admin-panel-accordion h3,
.admin-panel-accordion p {
  margin-top: 15px;
  margin-left: 35px;
}

.admin-panel-accordion h3 {
  font-size: 18px;
  font-weight: bold;
}

.admin-panel-accordion p {
  margin-top: 15px;
}

.accordion__button {
  padding: 20px !important;
  border-radius: 14px;
  box-shadow: 4px 4px 6px rgb(229 233 254 / 55%);
}

.accordion__button:before {
  display: none !important;
}

.accordion__button:hover {
  background: #f4f4f4 !important;
}

.accordion__item + .accordion__item {
  border: none !important;
}

.accordion__panel {
  background: #f4f4f4 !important;
  box-shadow: 4px 4px 6px rgb(229 233 254 / 55%);
  border-radius: 14px;
  animation: fadein 0.1s ease-in !important;
  padding-bottom: 2% !important;
}

.accordion__item {
  margin-bottom: 30px;
}

.accordion__button::content {
  border-bottom-left-radius: 0;
}

.input-group {
  max-width: 19%;
  max-height: 15%;
  margin-left: 35px;
}

.form-group {
  max-width: 100% !important;
  max-height: 15% !important;
  margin-left: 35px !important;
}

.form-btn {
  margin-top: 54px;
  background: #5d5fef !important;
  color: white !important;
  height: 37px !important;
  margin-left: 33px;
}

.delete-btn{
  background:#fc4f5a !important;
  border:none !important;
}

.submit-form-btn {
  background: #5d5fef !important;
  color: white !important;
  height: 37px !important;
  margin-left: 33px;
  width: 100%;
}

.newEmployeeBtn {
  background: #5d5fef !important;
  color: white !important;
  height: 37px !important;
  border: none;
  border-radius: 8px;
  padding: 0 15px;
  margin-left: 35px;
  font-weight: bold;
}

.event-form-btn {
  margin-top: 5px;
  background: #f4f4f4 !important;
  color: black !important;
  width: 20% !important;
  height: 35px !important;
}

.input-bg {
  background: #f9f9f9 !important;
  color: black !important;
}

.event-form-group {
  max-height: 15% !important;
  margin-left: 35px !important;
}

.report-form-group {
  max-width: 19% !important;
  max-height: 15% !important;
}

.form-control {
  border: 1px solid #5d5fef !important;
}

#button-addon2 {
  border: 1px solid #5d5fef !important;
}

#button-addon2:hover {
  background: #5d5fef !important;
  color: white !important;
}

/* admin calendar */

.optionContainer {
  width: 100% !important;
}

.request-date-range {
  height: 40px !important;
}

.fc-event-main {
  color: black !important;
  overflow: hidden;
}

.fc-button-primary {
  background-color: #dee2e6 !important;
  border: 1px solid #ced4da !important;
}

.optionContainer li:hover,
.optionContainer .highlight {
  background: #6666ff !important;
  color: #fff;
}

.optionContainer input[type="checkbox"] {
  accent-color: #8080ff;
}

input[name="google"] {
  accent-color: #57cc99;
  color: white;
}

input[name="birthday"] {
  accent-color: #fbbc04;
}

input[name="leave"] {
  accent-color: #fc4f5a;
}

input[name="custom"] {
  accent-color: #8080ff;
}

/* leave request calendar */

.request-page {
  max-width: 350px;
  margin: 0 auto;
}

.request-submitBtn {
  width: 80px;
  padding: 5px;
  background: #6666FF;
  color: white;
  border: 1px solid #6666FF;
  margin-top: 160px;
  margin-bottom: 50px;
  margin-left: 50%;
}

.report-btn {
  width: 120px;
  padding: 5px 15px;
  background: #6666ff !important;
  color: white !important;
  border: 1px solid #6666ff;
  margin-top: 1px;
  height: 42px;
}

.dropdown-menu-section {
  display: flex;
  gap: 30px;
  align-items: center;
}

/* leave request warning page */

.request-page-warning-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  max-width: 450px;
  margin: 0 auto;
  border: 3px solid #6666ff;
  box-shadow: 4px 4px 6px #6666ff;
  padding: 25px;
  position: relative;
  border-radius: 8px;
}

.warning-icon {
  width: 80px;
  filter: invert(88%) sepia(62%) saturate(4985%) hue-rotate(260deg)
    brightness(98%) contrast(105%);
}

.request-page-warning-text {
  text-align: center;
}

.request-page-warning-title {
  font-size: 30px;
  font-weight: bold;
  color: #575a5d;
}

.request-page-warning-subtext {
  font-size: 16px;
  color: #8a8888;
}

/* admin leave request */

.download-excel-btn {
  padding: 11px 35px;
  border-radius: 8px;
  background-color: palevioletred;
  font-weight: 700;
  border: none;
  color:white;
  text-decoration: none;

}

.download-excel-btn:hover{
  color:white;
  opacity: 0.8;
}

#__react-alert__ > div > div > div {
  background: #6666ff !important;
}

.dd-wrapper .dd-header {
  border: 1px solid #5d5fef !important;
  border-radius: 8px !important;
}

.approved {
  background: rgb(0, 187, 131) !important;
  color: white !important;
}

#uncontrolled-tab-example-tab-all {
  border: none;
}

#uncontrolled-tab-example-tab-approved {
  border: none;
}

#uncontrolled-tab-example-tab-pending {
  border: none;
}

#uncontrolled-tab-example-tab-rejected {
  border: none;
}

.pending {
  background: rgb(254, 203, 71) !important;
  color: white !important;
}

.rejected {
  background: rgb(240, 0, 0) !important;
  color: white !important;
}

.nav-tabs {
  border: none !important;
  margin-left: 12px !important;
}

.active {
  background: #f0f0f0 !important;
}

.download-excel-btn {
  padding: 11px 35px;
  border-radius: 8px;
  background-color: rgb(252, 79, 90);
  font-weight: 700;
  border: none;
  margin-top: 65px;
  margin-bottom: 50px;
  margin-left:5px;
}

/* admin panel */

.table-deleteBtn {
  background: #fc4f5a;
  color: white !important;
  border: none;
  padding: 5px 12px;
  border-radius: 4px;
  margin-top: 13px;
}

.table-updateBtn {
  background: #6666ff;
  color: white !important;
  border: none;
  padding: 5px 12px;
  border-radius: 4px;
  margin-top: 13px;
}

/* admin panel employees */
.current-employees-select {
  margin-left: 30px;
  width: 100%;
}

/* employee add update page */

.employeedetailnavbar {
  display: flex;
  justifycontent: center;
  alignitems: center;
  width: 100%;
  height: 65px;
  background: #6666ff;
  margin: 0;
  position: fixed;
}

.employeedetailnavbar button {
  padding: 10px;
  border-radius: 0px;
  margin-top: 27px;
  margin-left: 30px;
  color: white !important;
  background: #6666ff;
  border: none;
  font-weight: bold;
}

.employeedetailnavbar .active {
  background-color: white !important;
  color: black !important;
}
