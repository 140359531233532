.item1 { grid-area: item1; }
.item2 { grid-area: item2; }
.item3 { grid-area: item3; }
.item4 { grid-area: item4; }
.item5 { grid-area: item5; }
.item6 { grid-area: item6; }
.item7 { grid-area: item7; }
.item8 { grid-area: item8; }
.item1-9 { grid-area: item1-9;}

/* .item1-9 input{
    width: 95% !important;
} */
.form-2{
    display: grid;
    grid:
    'item1 item2'
    'item3 item4'
    'item5 item6'
    'item7 item8'
    'item1-9';
    grid-gap: 20px;
    padding-top: 100px;
}

.form-2>div{
    padding-bottom: 50px;
}
.form-2 input,
.form-2 select { 
    width: 100%;
    height: 41px;
    border: 1px solid #6666FF;
}

.form-2 select {
    width:100% !important;
}

.form-2 input:focus,
.form-2 select:focus {
    outline: none;
}
.form-2 label {
    display: block;
    padding-bottom: 10px;
}
.GeneralForm{
    padding-top: 120px;
    padding-left: 166px;
    padding-right: 166px;
}


.item1-1 input {
    background:#E4E8FE
}

.item1-3 select {
    background:#E4E8FE
}

.btngroup{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}
.updatebtn{
    padding: 11px 24px;
    margin-right: 15px;
    background-color: #6666FF;
    border-radius: 0;
    border: none;
    font-size: 16px;
    font-weight: 800;
    color: white;
    cursor: pointer;
}
.nextbtn{
    padding: 11px 24px;
    margin-right: 25px;
    background-color: #FD8289;
    border-radius: 0;
    border: 1px solid #6666FF;
    font-size: 16px;
    font-weight: 800;
    color: white;
    cursor: pointer;
}
