.UpdateNavbar{
    width: 100%;
    height: 80px;
    background-color: #6666FF;
    display: flex;
    align-items: end;
    position:fixed;
}

.UpdateNavbar a.active {
    background-color: white !important;
    color: black;
    font-weight: 800 !important;
  }


.UpdateNavbar>div{
    padding-left: 75px;
    padding-bottom: 12px;
}
@media only screen and (max-width: 1294px) {
    .UpdateNavbar>div{
        padding-left: 0px;
        padding-bottom: 12px;
    }
   
  }
.UpdateNavbar>div>a{
    text-decoration: none;
    color: white;
    padding: 15px 25px;
    font-weight: 800;
}
@media only screen and (max-width: 1200px) {
    .UpdateNavbar>div>a{
        padding: 15px 20px;
    }
  }
  @media only screen and (max-width: 1120px) {
    .UpdateNavbar>div>a{
        padding: 10px 10px;
    }
  }
.UpdateNavbar>div>a:hover{
    color: black;
    background-color: white;
}
.UpdateNavbar>div>a:focus{
    color: black;
    background-color: white;
}
.activeNav{
    background-color: white !important;
    color: black !important;
}