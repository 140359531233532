#calendar {
  width: 55%;
  box-sizing: border-box;
  color: black !important;
  margin-top: 50px;
  margin-bottom:50px
}


.wrapper {
  width:55%;
  margin:0 auto;
  height: 55%;
}

.gAPhwo {
  background: white !important;
}

.gAPhwo > h1 {
  color: black !important;
}

.bvGvIc {
  /* background: white !important; */
  color: black !important;
}

.fc-button-group span {
  color:black !important;
}
/* 
span {
  color: white !important;
} */

button {
  color: black !important;
}

.eFeZuQ span::after {
  background: #ff7780 !important;
}

/* .jLKOIc span::after {
  background: blue !important;
} */

.dLoBpi {
  border: none !important;
}

.bvGvIc {
  box-shadow: none !important;
  border-right: 1px solid #e9ecef !important;
}

.sc-dkrFOg {
  background: none !important;
}

.sc-dkrFOg:hover {
  cursor: pointer;
  background: #f8f9fa !important;
}

.hmhdPi {
  background: white !important;
  box-shadow: none !important;
  border-right: 1px solid #e9ecef !important;
}

.iLbGco > h1 {
  color: black !important;
}

.request-submitBtn:hover {
  cursor: pointer;
}

.gUgoOa > div > div:first-of-type {
  color: black !important;
  font-weight: bold;
}

.gUgoOa > h1 {
  color: black !important;
}



.hGdZQW span::after {
  background: none !important;
}

.bwhsna span::after {
  background: none !important;
}

.request-date-range {
  width: 200px;
  height: 20px;
  margin-top:15px;
  margin-bottom:50px;
  border: 1px solid #5d5fef;
  border-radius: 8px;
}

.sc-gKPRtg > h1 {
  display:none;
} 

.calendar-top button:hover {
  cursor:pointer;
}

.calendar-top span {
  font-weight: bold;
  font-size: 22px;
}

.optionContainer {
  width:20%;
}

.flXVBG {
  visibility:hidden;
}

.dZyTRB {
  background: rgb(185, 255, 119) !important;
  background-color: rgb(185, 255, 119) !important;
} 


.singleDayEvent{
  background-color: red;
}


.rdp {
  --rdp-cell-size: 90px;
  --rdp-accent-color: #FC4F5A;
  --rdp-background-color: #da848a  ;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #FC4F5A  ;
  --rdp-background-color-dark: #FC4F5A  ;
  /* Outline border for focused elements */
  --rdp-outline: 1px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}



