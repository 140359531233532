.item1 { grid-area: item1; }
.item2 { grid-area: item2; }
.item3 { grid-area: item3; }
.item4 { grid-area: item4; }
.item5 { grid-area: item5; }
.item6 { grid-area: item6; }
.dateForm1{ grid-area: dateForm1;}
.dateForm2{ grid-area: dateForm2;}
.item7 { grid-area: item7; }
.item8 { grid-area: item8; }
.item9 { grid-area: item9; }
.item10 { grid-area: item10; }
.item11 { grid-area: item11; }
.item12 { grid-area: item12; }
.label1 { grid-area: label1;}
.label2 { grid-area: label2;}

.form1{
    display: grid;
    grid:
    'item1 item2'
    'item3 item4'
    'item5 item6'
    'dateForm1 dateForm2';
    grid-gap: 10px;
    padding-top: 100px;
}
.dateForm1{
    display: grid;
    grid: 'label1 label1 label1 label1 label1 label1 label1 label1 label1' 
    'item7 item7 item7 item7 item8 item9 item9 item9 item9';
}
.dateForm2{
    display: grid;
    grid: 'label2 label2 label2 label2 label2 label2 label2 label2 label2' 
    'item10 item10 item10 item10 item11 item12 item12 item12 item12';
}
.form1>div{
    padding-bottom: 50px;
}
.form1 input,
.form2 input{ 
    width: 90%;
    height: 41px;
    border: 1px solid #6666FF;
}
.form1 input:focus,
.form2 input:focus{
    outline: none;
}
.form1 label,
.form2 label{
    padding-bottom: 10px;
    display: block;
}
.GeneralForm{
    padding-top: 120px;
    padding-left: 166px;
    padding-right: 166px;
}

.dateForm1 input{
    width: 72%;

}
.dateForm2 input{
    width: 73%; 
}

.item1 input,
.item2 input,
.item3 input,
.item7 input,
.item8 input,
.item9 input,
.item10 input,
.item11 input,
.item12 input
{
    background:#E4E8FE
}

.item13 select,
.item14 select {
    background:#E4E8FE
}

.btngroup{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}
.updatebtn{
    padding: 11px 24px;
    margin-right: 15px;
    background-color: #6666FF;
    border-radius: 0;
    border: none;
    font-size: 16px;
    font-weight: 800;
    color: white;
    cursor: pointer;
}
.nextbtn{
    padding: 11px 24px;
    margin-right: 25px;
    background-color: #FD8289;
    border-radius: 0;
    border: 1px solid #6666FF;
    font-size: 16px;
    font-weight: 800;
    color: white;
    cursor: pointer;
}