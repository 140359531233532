.item2-1 { grid-area: item1; padding-bottom: 18px; padding-top: 120px; }
.item3-2 { grid-area: item2; padding-bottom: 82px;}
.label-1{ grid-area: label1; font-size: 20px; font-weight: 800; padding-top: 150px;}

.form-1{
    display: grid;
    grid:
    'label1 label1'
    'item1 item1'
    'item2 item2'
    'item3 item3';
    grid-gap: 10px;
}

.form1>div{
    padding-bottom: 50px;
}
.form-1 input{ 
    width: 90%;
    height: 41px;
    border: 1px solid #6666FF;
}
.form-1 input:focus{
    outline: none;
}
.form-1 label{
    padding-bottom: 10px;
    display: block;
}
.GeneralForm{
    padding-top: 120px;
    padding-left: 166px;
    padding-right: 166px;
}
.btngroup{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}
.updatebtn{
    padding: 11px 24px;
    margin-right: 15px;
    background-color: #6666FF;
    border-radius: 0;
    border: none;
    font-size: 16px;
    font-weight: 800;
    color: white !important;
    cursor: pointer;
}

.updateSocialbtn {
    padding: 11px 24px;
    margin-left:35%;
    background-color: #6666FF;
    border-radius: 0;
    border: none;
    font-size: 16px;
    font-weight: 800;
    color: white !important;
    cursor: pointer;
}

.social-media-main {
    margin: 100px auto;
}

.social-media {
    display:flex;
    align-items:first baseline;
  }

  .social-input-discord input{
    width:500px;
    margin-left: 30px;
  }

  .social-input-linkedin input{
    width:500px;
    margin-left: 27px;
  }

  .social-input-twitter input{
    width:500px;
    margin-left: 38px;
  }

.input-group-text {
    border:1px solid #6666FF !important;
    background:white !important;
    border-radius:0 !important;
}

.borderRadius-none {
    border-radius:0 !important;
}