.reqblock{
    width: 97%;
    height: 299px;
    background-color: #F9F9F9;
    border-radius: 14px;
    box-shadow: 4px 4px 6px rgba(229, 233, 254, 0.55);
}
.coltwo h5{
    font-size: 20px;
    font-weight: 600;
}
.leavebar a{
    text-decoration: none;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 50px;
    padding-bottom: 20px;
}
.leavebar a:first-child{
    color: black;
    background-color: #F0F0F0;
    border-top-right-radius: 25px;
}

/* event */

.main {
    margin-left:100px !important;
    margin-bottom:15px !important;
}

.notification-event-title {
   font-weight:bold;
   font-size:22px;

}

.notification-birthday-title {
    margin-top:90px;
    font-weight:bold;
    font-size:22px;
}

.notification-anniversary-title {
    font-weight:bold;
    font-size:22px;
}

