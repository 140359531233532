/* .item2-1 { grid-area: item1; padding-bottom: 18px; padding-top: 120px; }
.item-2 { grid-area: item2; padding-bottom: 18px;}
.item-3 { grid-area: item3; padding-bottom: 82px;}
.item-4{ grid-area: item4; padding-bottom: 82px;}
.label-1{ grid-area: label1; font-size: 20px; font-weight: 800; padding-top: 150px;}

.form-1{
    display: grid;
    grid:
    'label1 label1'
    'item1 item1'
    'item2 item2'
    'item3 item3'
    'item4 item4';
    grid-gap: 10px;
} */

.form1>div{
    padding-bottom: 50px;
}
.form-1 input{ 
    width: 90%;
    height: 41px;
    border: 1px solid #6666FF;
}
.form-1 input:focus{
    outline: none;
}
.form-1 label{
    padding-bottom: 10px;
}
.form{
    padding-top: 120px;
    padding-left: 166px;
    padding-right: 166px;
}
.btngroup{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}
.updatebtnNew{
    padding: 11px 24px !important;
    margin-left: 45%;
    margin-top:30px;
    margin-bottom:30px;
    background-color: #6666FF !important;
    border-radius: 0px !important;
    border: none !important;
    font-size: 16px !important;
    font-weight: 800;
    color: white !important;
    cursor: pointer;
}

.mb-3 {
    margin-top:50px;
}



.form-control-input {
    width: 90%;
    height: 41px;
    border: 1px solid #6666FF;
    border-radius: 0px !important;
}

.form-control {
    transition:none !important;
}

.margin {
    margin-top:50px;
    margin-bottom: 120px;
}